// style for the profile page
.profile-page{

    .photo-container{
        width: 200px;
        height: 200px;
    }
    
    h5.description{
        max-width: 1000px;
        margin: 20px auto 25px;
    }

}

.portfolio-page {

    .section-projects {
        .projects .project img {
            max-width: 225px;
            min-height: 200px;
        }

        .project {
            display: flex;
            height: 100%;
            flex-direction: column;
            align-items: center;
        }
        
        .project-buttons {
            margin-top: auto;
            margin-bottom: 30px;
        }
    }
}
