@media screen and (min-width: 768px){
    .section-about {
        display: flex;
        flex-wrap: wrap;
        
        & > * {
            text-align: left;
            margin: 0;
            width: 50%;
        }
    
        .image-container{
            height: 335px;
            position: relative;
            background-position: center center;
            background-size: cover;
            box-shadow: $box-shadow-raised;
            border-radius: .25rem;
    
            &.image-left{
                margin-right: -80px;
                
                &:nth-child(2) {
                    width: calc(50% + 20px);
                }
                
                &:nth-child(6) {
                    z-index: 2;
                    margin-top: -365px;
                    margin-left: -80px;
                    width: calc(50% + 120px);
                }
            }
            
            &.image-right{
                margin-left: 40px;
                margin-bottom: 0px;
                top: 0;
                
                &:nth-child(3) {
                    top: 80px;
                    margin-bottom: 130px;
                    width: calc(50% + 20px);
                }
                
                &:nth-child(7) {
                    z-index: 1;
                    margin-top: 40px;
                    margin-right: -120px;
                    width: calc(50% + 120px);
                }
            }
        }
        
        div.story-center {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 25px;
            text-align: center;
        }
        
        div.story-left {
            padding-right: 70px;
                
            &:nth-child(4) {
                margin-top: -80px;
            }
                
            &:nth-child(8) {
                margin-top: -340px;
            }
        }
        
        div.story-right {
            padding-left: 70px;
                
            &:nth-child(9) {
                margin-top: 50px;
            }
        }
    }
}

@media screen and (min-width: 1200px){
    .section-about {
        .image-container{
    
            &.image-left{
                
                &:nth-child(6) {
                    margin-top: -250px;
                }
            }
        }
        
        div.story-left {
            &:nth-child(8) {
                margin-top: -235px;
            }
        }
    }
}

@media screen and (max-width: 768px){
    .section-about {
        text-align: center;
        & > * {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0;
            width: 100%;
        }
        
        & :nth-child(1) { order: 1; }
        & :nth-child(2) { order: 2; }
        & :nth-child(4) { order: 2; }
        & :nth-child(3) { order: 3; }
        & :nth-child(5) { order: 4; }
        & :nth-child(6) { order: 5; }
        & :nth-child(8) { order: 6; }
        & :nth-child(7) { order: 7; }
        & :nth-child(9) { order: 8; }
        
        .image-container {
            height: 230px;
            margin-bottom: 25px;
        }
    }
}

@media screen and (max-width: 576px){
    .page-header {
        .content-center.brand {
            h5 {
                font-size: $font-size-base
            }
        }
    }
}
